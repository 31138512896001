import { createApp } from "vue";
import App from "./Dashboard.vue";
import router from "./router";
import store from "./store";
import { VueHeadMixin, createHead } from "@unhead/vue";
import axiosInstance from "@modules/axios"; // Import the Axios instance
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // Import the CSS for vue3-toastify

const head = createHead();

var app = createApp(App)
  .mixin(VueHeadMixin)
  .use(head)
  .use(router)
  .use(store)
  .use(Vue3Toastify, {
    autoClose: 3000,
    theme: "dark",
    position: "bottom-right",
  });

app.config.globalProperties.$axios = axiosInstance;

app.mount("#app");
