import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    dashboard_details: {},
  },
  getters: {
    getUser: (state) => () => {
      return state.user;
    },
    isAuthenticated: (state) => () => !!state.user,
    getData: (state) => (name) => {
      return state[name];
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user; // Set the user data
    },
    setDashboardDetails(state, details) {
      state.dashboard_details = details;
    },
    clearUser(state) {
      state.user = null; // Clear the user data
    },
    changeData(state, payload) {
      for (let i = 0; i < Object.keys(payload).length; i++) {
        const key = Object.keys(payload)[i];
        const value = payload[key];
        state[key] = value;
      }
    },
  },
  actions: {
    changeData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("changeData", payload);
        resolve();
      });
    },
  },

  modules: {},
});
