<template>
  <div class="list-container">
    <span class="list-title" v-if="title != ''"> {{ title }} </span>
    <ListComponent v-for="(item, index) in data" :key="index" :data="item" />
  </div>
</template>

<script>
import ListComponent from "./ListComponent.vue";
export default {
  name: "ListParent",
  components: { ListComponent },
  created() {},
  props: {
    data: {
      required: true,
      type: Array,
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@tailwind components;

@layer components {
  .list-container {
    .list-title {
      @apply px-3 text-brand-gray text-sm font-normal select-none;
    }
  }
}
</style>
