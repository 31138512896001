/**
 * Asynchronously saves data to localStorage, converting objects to JSON strings.
 * @param {string} key - The key under which to store the data.
 * @param {any} value - The data to store, objects are converted to JSON strings.
 * @return {Promise<void>} A promise that resolves when the data is stored.
 */
function storageSet(key, value) {
    return new Promise((resolve, reject) => {
        try {
            if (typeof value === 'object') {
                value = JSON.stringify(value); // Convert objects to JSON string
            }
            localStorage.setItem(key, value);
            resolve();
        } catch (error) {
            console.error('Failed to save to localStorage:', error);
            reject(error); // Reject the promise if an error occurs
        }
    });
}

/**
 * Asynchronously retrieves data from localStorage, parsing JSON strings back into objects.
 * @param {string} key - The key from which to retrieve the data.
 * @return {Promise<any>} A promise that resolves with the retrieved data, parsed as JSON if applicable.
 */
function storageGet(key) {
    return new Promise((resolve, reject) => {
        try {
            const value = localStorage.getItem(key);
            try {
                resolve(JSON.parse(value)); // Try to parse as JSON and resolve
            } catch (e) {
                resolve(value); // If parsing fails, resolve as plain text
            }
        } catch (error) {
            console.error('Failed to retrieve from localStorage:', error);
            reject(error); // Reject the promise if an error occurs
        }
    });
}

/**
 * Asynchronously removes a specific item from localStorage.
 * @param {string} key - The key of the item to remove.
 * @return {Promise<void>} A promise that resolves when the item is removed.
 */
function storageRemove(key) {
    return new Promise((resolve, reject) => {
        try {
            localStorage.removeItem(key);
            resolve();
        } catch (error) {
            console.error('Failed to remove item from localStorage:', error);
            reject(error); // Reject the promise if an error occurs
        }
    });
}
/**
 * Asynchronously clears all data from localStorage.
 * @return {Promise<void>} A promise that resolves when all data is cleared.
 */
function storageClear() {
    return new Promise((resolve, reject) => {
        try {
            localStorage.clear();
            resolve();
        } catch (error) {
            console.error('Failed to clear localStorage:', error);
            reject(error); // Reject the promise if an error occurs
        }
    });
}
export default {
    get: storageGet,
    set: storageSet,
    remove: storageRemove,
    clear: storageClear
}
