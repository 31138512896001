<template>
  <div
    ref="container"
    v-if="src"
    class="svg-container flex items-center justify-center"
    v-html="processedSvg"
  ></div>
</template>

<script>
function changeColor(data, color) {
  return data
    .replace(/(<path[^>]*?)stroke="[^"]*"/g, `$1stroke="${color}"`)
    .replace(/(<path[^>]*?)fill="[^"]*"/g, `$1fill="${color}"`);
}

export default {
  name: "SVGIcon",
  props: {
    noHoverRef: {
      type: Boolean,
      default: true,
    },

    hoverRef: {
      type: HTMLElement,
      default: null,
    },
    src: {
      type: String,
      required: true,
    },
    normalColor: {
      type: String,
      default: null,
    },
    hoverColor: {
      type: String,
      default: null,
    },
    customColoring: {
      type: Function,
      default: changeColor,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  updated() {
    this.applySvgStyles();
  },

  mounted() {
    if (this.noHoverRef) {
      this.addHoverListeners();
      this.applySvgStyles();
    }
  },
  beforeDestroy() {
    this.removeHoverListeners();
  },
  watch: {
    hoverRef: {
      handler(next, prev) {
        // Clean up previous listeners if `prev` is defined
        if (prev) {
          this.removeHoverListeners(prev);
        }

        // Add new listeners if `next` is defined
        if (next) {
          this.addHoverListeners(next);
        }
      },
      immediate: true, // This ensures that the watcher is invoked immediately
    },
  },
  methods: {
    applySvgStyles() {
      if (this.$refs.container) {
        this.$refs.container.querySelectorAll("svg").forEach((svg) => {
          svg.style.cssText = "width: 100% !important; height: 100% !important;";
        });
      }
    },
    addHoverListeners(element) {
      const target = element || this.hoverRef || this.$refs.container;
      if (target) {
        target.addEventListener("mouseenter", this.handleMouseEnter);
        target.addEventListener("mouseleave", this.handleMouseLeave);
      }
    },
    removeHoverListeners(element) {
      const target = element || this.hoverRef || this.$refs.container;
      if (target) {
        target.removeEventListener("mouseenter", this.handleMouseEnter);
        target.removeEventListener("mouseleave", this.handleMouseLeave);
      }
    },
    handleMouseEnter() {
      this.hovered = true;
    },
    handleMouseLeave() {
      this.hovered = false;
    },
    changeColor(data, color) {
      return changeColor(data, color);
    },
  },
  computed: {
    processedSvg() {
      if (this.hovered && this.hoverColor) {
        return this.customColoring(this.src, this.hoverColor);
      }
      if (this.normalColor) {
        return this.customColoring(this.src, this.normalColor);
      }
      return this.src;
    },
  },
};
</script>
