<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  head() {
    return {
      title: "Home | ClassPass",
    };
  },
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@assets/extra-default.scss";
@tailwind components;

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@layer components {
  html {
    * {
      @apply font-inter;
    }
    @apply bg-brand-blue-300;
    body {
      @apply m-0;
    }
  }
}
</style>
