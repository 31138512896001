<template>
  <router-view></router-view>
</template>

<script>
import { RouterView } from "vue-router";
export default {
  name: "EmptyLayout",
  components: { RouterView },
};
</script>
