<template>
  <div class="container-div">
    <div class="left-panel">
      <div class="top-part">
        <div class="logo-container">
          <img :src="imageSrc.FullLogoSVG" alt="Full Logo" />
        </div>
        <ListParent
          class="mb-6"
          v-for="(navBarItem, index) in navBarItems"
          :title="navBarItem.title"
          :data="navBarItem.data"
          :key="index"
        />
      </div>
      <div class="bottom-part"></div>
    </div>
    <div class="right-panel">
      <Header />
      <router-view />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { RouterView } from "vue-router";
import ListParent from "@components/DashboardComponents/NavBar/ListParent.vue";
import illustrationImage from "@assets/login-page/illustration-login.png";
import HarvardLogo from "@assets/login-page/harvard-logo.png";
import MITLogo from "@assets/login-page/mit-uni-logo.png";
import USCLogo from "@assets/login-page/usc-uni-logo.png";
import VirginiaLogo from "@assets/login-page/virginia-uni-logo.png";
import FullLogoSVG from "@assets/full-logo.svg";
import colorSpreadImage from "@assets/login-page/color-spread.png";
import LogInButtonArrow from "@assets/login-page/log-in-button-arrow.svg";
import DashboardIcon from "!!raw-loader!@assets/dashboard-page/dashboard-icon.svg";
import DashboardActiveIcon from "!!raw-loader!@assets/dashboard-page/dashboard-icon-active.svg";
import AccountIcon from "!!raw-loader!@assets/dashboard-page/account-icon.svg";
import AccountActiveIcon from "!!raw-loader!@assets/dashboard-page/account-icon-active.svg";
import SetupIcon from "!!raw-loader!@assets/dashboard-page/setup-icon.svg";
import DownloadIcon from "!!raw-loader!@assets/dashboard-page/download-icon.svg";
import Header from "@components/DashboardComponents/Header/Header.vue";

export default {
  name: "DashboardBaseView",
  data() {
    return {
      navBarItems: [
        {
          title: "MAIN",
          data: [
            {
              route: "dashboard",
              icon: DashboardIcon,
              title: "Dashboard",
            },
            {
              route: "account",
              icon: AccountIcon,
              title: "Account",
            },
          ],
        },
        {
          title: "Tools",
          data: [
            {
              route: "https://classpass.study/",
              icon: SetupIcon,
              title: "Setup",
              isExternal: true,
            },
            {
              route: "https://classpass.study/",
              icon: DownloadIcon,
              title: "Download",
              isExternal: true,
            },
          ],
        },
      ],
      imageSrc: {
        illustrationImage,
        HarvardLogo,
        MITLogo,
        USCLogo,
        VirginiaLogo,
        FullLogoSVG,
        colorSpreadImage,
        LogInButtonArrow,
      },
    };
  },
  components: {
    Header,
    RouterView,
    ListParent,
  },
  head() {
    return {
      title: "Account | ClassPass",
    };
  },
};
</script>
<style lang="scss" scoped>
@tailwind components;

@layer components {
  .flex-wrap-initial {
    flex-wrap: unset;
  }
  .container-div {
    @apply min-h-screen;
    @apply flex overflow-x-hidden;
    > .left-panel {
      @apply w-[15%];
      @apply bg-brand-blue-400;
      @apply flex flex-col justify-between;
      @apply py-5 px-3;
      > * {
        @apply mx-auto;
      }
      > .top-part {
        .logo-container {
          @apply mb-5 w-[60%];
        }
        .input:not(:last-of-type) {
          @apply mb-4;
        }
        .forgot-text-container {
          @apply my-2;
        }
        .login-btn {
          @apply w-full  rounded-[5px] flex gap-2 flex-row justify-center items-center h-12 text-base font-medium hover:opacity-85 transition-all;
        }
        .first-login-button-container {
          @apply mt-12;
          @apply bg-brand-main text-brand-white;
        }
        .or-line-container {
          @apply text-brand-gray text-xs flex flex-row items-center my-5 flex justify-between;
          hr {
            @apply border-brand-blue-100 w-[44%];
          }
        }
        .second-login-button-container {
          @apply bg-brand-blue-200 text-brand-gray hover:text-brand-white;
        }
      }
      > .bottom-part {
        @apply w-full;
        span {
          @apply text-brand-gray text-center;
          a {
            @apply text-brand-main;
          }
        }
      }
    }
    > .right-panel {
      @apply w-full;
      @apply bg-brand-blue-300;
      > * {
        @apply px-6;
      }
    }
  }
}
</style>
