import axios from "axios";
import storage from "@modules/storage/localStorage";
const isDev =
  process.env.isDev == "true" && process.env.NODE_ENV === "development";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL:
    (isDev == true
      ? window.location.origin
      : "https://dashboard.classpass.study") + "/api", // Replace with your API base URL
});

// Add a request interceptor to include the JWT token in the headers
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await storage.get("jwt_token"); // Replace with your method of retrieving the token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const message =
        error.response || error.response.statusText || "An error occurred";
      return Promise.reject(message);
    } else if (error.request) {
      // The request was made but no response was received
      return Promise.reject(new Error("No response received from the server"));
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(new Error(error.message));
    }
  }
);

export default axiosInstance;
